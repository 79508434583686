import { Backoff } from '../../../utils/Backoff';
import { MY_SEGMENT } from '../../../utils/constants';
/**
 * MySegmentsUpdateWorker factory
 */
export function MySegmentsUpdateWorker(mySegmentsSyncTask, telemetryTracker) {
  var maxChangeNumber = 0; // keeps the maximum changeNumber among queued events
  var currentChangeNumber = -1;
  var handleNewEvent = false;
  var isHandlingEvent;
  var _segmentsData; // keeps the segmentsData (if included in notification payload) from the queued event with maximum changeNumber
  var backoff = new Backoff(__handleMySegmentsUpdateCall);
  function __handleMySegmentsUpdateCall() {
    isHandlingEvent = true;
    if (maxChangeNumber > currentChangeNumber) {
      handleNewEvent = false;
      var currentMaxChangeNumber_1 = maxChangeNumber;
      // fetch mySegments revalidating data if cached
      mySegmentsSyncTask.execute(_segmentsData, true).then(function (result) {
        if (!isHandlingEvent) return; // halt if `stop` has been called
        if (result !== false) {
          // Unlike `Splits|SegmentsUpdateWorker`, we cannot use `mySegmentsCache.getChangeNumber` since `/mySegments` endpoint doesn't provide this value.
          if (_segmentsData) telemetryTracker.trackUpdatesFromSSE(MY_SEGMENT);
          currentChangeNumber = Math.max(currentChangeNumber, currentMaxChangeNumber_1); // use `currentMaxChangeNumber`, in case that `maxChangeNumber` was updated during fetch.
        }
        if (handleNewEvent) {
          __handleMySegmentsUpdateCall();
        } else {
          backoff.scheduleCall();
        }
      });
    } else {
      isHandlingEvent = false;
    }
  }
  return {
    /**
     * Invoked by NotificationProcessor on MY_SEGMENTS_UPDATE event
     *
     * @param {number} changeNumber change number of the MY_SEGMENTS_UPDATE notification
     * @param {SegmentsData | undefined} segmentsData might be undefined
     */
    put: function (changeNumber, segmentsData) {
      if (changeNumber <= currentChangeNumber || changeNumber <= maxChangeNumber) return;
      maxChangeNumber = changeNumber;
      handleNewEvent = true;
      _segmentsData = segmentsData;
      if (backoff.timeoutID || !isHandlingEvent) __handleMySegmentsUpdateCall();
      backoff.reset();
    },
    stop: function () {
      isHandlingEvent = false;
      backoff.reset();
    }
  };
}