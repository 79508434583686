var KinTab_1;
import { __decorate } from "tslib";
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import baseStyles from '../styles/base.styles.js';
import componentStyles from './kin-tab.css.js';
/**
 * Tab Component
 *
 * @element kin-tab
 * @property [disabled=false] - Disables tab.
 * @slot default - Default lot for tab content.
 */
let KinTab = KinTab_1 = class KinTab extends LitElement {
  constructor() {
    super();
    this.disabled = false;
    this.selected = false;
    this.buttonId = `${this.tagName.toLowerCase()}-${KinTab_1.instanceCount}`;
    KinTab_1.instanceCount += 1;
  }
  firstUpdated() {
    this.setAttribute('role', 'tab');
    if (!this.hasAttribute('id')) {
      this.id = this.buttonId;
    }
  }
  updated(propertyChanges) {
    if (propertyChanges.has('selected')) {
      if (this.selected) {
        this.setAttribute('selected', '');
        this.setAttribute('tabindex', '0');
        this.setAttribute('aria-selected', 'true');
      } else {
        this.removeAttribute('selected');
        this.setAttribute('tabindex', '-1');
        this.setAttribute('aria-selected', 'false');
      }
    }
    if (propertyChanges.has('disabled')) {
      if (this.disabled) {
        this.setAttribute('aria-disabled', 'true');
      } else {
        this.removeAttribute('aria-disabled');
      }
    }
  }
  render() {
    return html`<slot
      class=${classMap({
      tab: true,
      'tab--selected': this.selected,
      'tab--disabled': this.disabled
    })}
    ></slot>`;
  }
  /** Selects the tab. */
  select() {
    this.selected = true;
  }
  /** Deselects the tab. */
  deselect() {
    this.selected = false;
  }
};
KinTab.styles = [baseStyles, componentStyles];
KinTab.instanceCount = 0;
__decorate([property({
  type: Boolean
})], KinTab.prototype, "disabled", void 0);
__decorate([state()], KinTab.prototype, "selected", void 0);
KinTab = KinTab_1 = __decorate([customElement('kin-tab')], KinTab);
export default KinTab;
