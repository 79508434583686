import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { NgKLoggerService } from '@kin/ngk-logger';
import { SplitFactory } from '@splitsoftware/splitio';
import SplitIO, { ImpressionData } from '@splitsoftware/splitio/types/splitio';
import { getCookie, setCookie } from 'app/common/utils/cookies/cookie-helpers';
import { BehaviorSubject } from 'rxjs';

interface SplitInitFactoryArgs {
  splitIOClientKey: string | undefined;
  userIdentifier: string | undefined;
  maxWaitTime?: number;
  splitFactory?: typeof SplitFactory;
}

export type SplitClientStatus = null | 'ready' | 'failed' | 'loading';

interface SplitIOClientCreationConfig {
  splitIOClientKey: string | undefined;
  userIdentifier: string;
}

const SPLITIO_COOKIE_NAME = 'kin-splitio-anonymous-key';

/**
 * This purpose of this service is to interact with SplitIO (https://www.split.io/), our feature flag integration.
 * We also send custom events to SplitIO that are used in company analytics.
 * Find login credentials in 1Password.
 */
@Injectable({
  providedIn: 'root',
})
export class SplitIOService {
  constructor(private logger: NgKLoggerService) {}

  /** A reference to the SplitIO client */
  private _clientRef: SplitIO.IClient | null = null;
  /**
   * Exposes the SplitIO client reference
   * API: https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK
   */
  public get clientRef(): SplitIO.IClient | null {
    return this._clientRef;
  }
  /** Expose observable stream; do not expose directly to prevent other consumers from emitting it */
  private _clientStatus$ = new BehaviorSubject<SplitClientStatus>(null);
  public clientStatus$ = this._clientStatus$.asObservable();

  /**
   * Creates the SplitIO client.
   * Required to be called before this service can be used.
   * Subscribe to isClientReady$ to be notified when client is ready.
   */
  public createSplitIOClient({ splitIOClientKey, userIdentifier }: SplitIOClientCreationConfig) {
    if (!this._clientRef) {
      this.setClientAsLoading();
      this.createClient({
        splitIOClientKey,
        userIdentifier: this.getOrCreateSplitIOCookie(userIdentifier),
      }).then((client) => {
        if (client) {
          this.setClient(client);
        } else {
          this.setClientAsFailed();
        }
      });
    } else {
      this.logger.event({ name: 'SplitIOService::createSplitIOClient', metaData: { message: 'the SplitIO client is already initialized' } });
    }
  }

  public getOrCreateSplitIOCookie(userIdentifier: string): string {
    const storedAnonymousKey = getCookie(SPLITIO_COOKIE_NAME);

    // If the cookie is already set, return the value
    if (storedAnonymousKey) {
      return storedAnonymousKey;
    }

    // If the cookie is not already set, set it and then return the value
    const uniqueValue = userIdentifier;

    setCookie({
      cookieName: SPLITIO_COOKIE_NAME,
      cookieValue: uniqueValue,
    });

    return uniqueValue;
  }

  /**
   * Allows the SplitIO client to be created outside of this service.
   * This should only be done once.
   * @param client
   */
  public setClient(client: SplitIO.IClient) {
    if (client && !this._clientRef) {
      this._clientRef = client;
      this.setClientAsReady();
    } else {
      this.logger.event({ name: 'SplitIOService::setClient', metaData: { message: 'the SplitIO client is already initialized' } });
    }
  }

  /** clientStatus$ Actions: trigger a new event on the clientStatus$ observable stream */
  /** Set Client As Ready */
  public setClientAsReady() {
    this._clientStatus$.next('ready');
  }

  /** Set Client As Failed */
  public setClientAsFailed() {
    this._clientStatus$.next('failed');
  }

  /** Set Client As Loading */
  public setClientAsLoading() {
    this._clientStatus$.next('loading');
  }

  /** Reset Client to null */
  public resetClientStatus() {
    this._clientStatus$.next(null);
  }

  public createClient({ splitIOClientKey, userIdentifier, maxWaitTime = 2, splitFactory = SplitFactory }: SplitInitFactoryArgs): Promise<SplitIO.IClient | null> {
    return new Promise<SplitIO.IClient | null>((resolve) => {
      if (splitIOClientKey && userIdentifier) {
        // Configuration docs here: https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK
        const factory = splitFactory({
          core: {
            authorizationKey: splitIOClientKey,
            key: userIdentifier,
          },
          startup: {
            readyTimeout: maxWaitTime,
          },
          impressionListener: {
            logImpression(impressionData: ImpressionData) {
              // Used to track feature flag evaluations in DataDog.
              if (datadogRum) {
                datadogRum.addFeatureFlagEvaluation(impressionData.impression.feature, impressionData.impression.treatment);
              }
            },
          },
        });

        // Create new client.
        const client: SplitIO.IClient = factory.client();

        // Wait for SplitIO client to start up or timeout.
        client.on(client.Event.SDK_READY, () => {
          resolve(client);
        });
        client.on(client.Event.SDK_READY_TIMED_OUT, () => {
          resolve(null);
        });
      } else {
        resolve(null);
      }
    });
  }

  /**
   * @returns the treatment from split.io
   */
  public getTreatment(splitName: string): string | undefined {
    if (this._clientRef) {
      return this._clientRef.getTreatment(splitName);
    }
    this.logger.event({ name: 'SplitIOService::getTreatment', metaData: { message: 'SplitIO client is not defined' } });
    return undefined;
  }

  /**
   * Gets both the treatment and its configuration for a given feature flag
   * @param featureFlagName name of the feature flag to evaluate
   * @returns The treatment configuration object or null if not found/client not ready
   */
  public getTreatmentWithConfig(featureFlagName: string): SplitIO.TreatmentWithConfig | null {
    if (!this._clientRef) {
      this.logger.event({ name: 'SplitIOService::getTreatmentWithConfig', metaData: { message: 'SplitIO client is not defined' } });
      return null;
    }

    return this._clientRef.getTreatmentWithConfig(featureFlagName);
  }

  /**
   * Sends an event to Split.io; see: https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#track.
   *
   * The SplitIO client's `track` method will return `true` if the event was tracked successfully, and `false` if the current queue size
   * is equal to the config set by `eventsQueueSize` or if an incorrect input to the track method has been provided--SplitIO will not tell us what the error is;
   * see the constraints around values provided to `track` here: https://help.split.io/hc/en-us/articles/360020585772-Events#using-the-sdk-track-method.
   *
   * @param trafficType - the type of traffic this event is associated with, e.g. 'user', 'anonymous', etc; this value *must* match
   * the traffic type defined in the Split.io dashboard for the feature flag. See: https://help.split.io/hc/en-us/articles/360019916311-Traffic-type
   * @param eventType - the name of the event, e.g. 'click', 'impression', etc; when tracking events related to feature flags,
   * we can supply a feature-flag-related value here, e.g. 'ar-quoting-single_quote_identifier__treatment_on'. A list of events in the SplitIO dashboard
   * can be viewed here: https://app.split.io/org/cbc88ea0-8647-11ea-bae8-0ed25467b33f/ws/cbd16840-8647-11ea-bae8-0ed25467b33f/admin/event-types
   * @param value - the value of the event, e.g. 1, 2, 3, etc; this is optional and we will send 0 by default.
   * @param properties - an object containing additional information about the event, e.g. { 'key': 'value' };
   * @returns void
   */
  public trackEvent(trafficType: string, eventType: string, value: number = 0, properties: SplitIO.Properties = {}) {
    // Every event sent to SplitIO will have the `event_source` property set to 'quoting-ui'.
    properties['event_source'] = 'quoting-ui';

    if (this._clientRef) {
      const result = this._clientRef.track(trafficType, eventType, value, properties);
      if (!result) {
        this.logger.event({ name: 'SplitIOService::trackEvent', metaData: { message: 'Something went wrong. The event was not tracked.' } });
      }
    } else {
      this.logger.event({ name: 'SplitIOService::trackEvent', metaData: { message: 'SplitIO client is not defined. The event was not tracked.' } });
    }
  }
}
