/**
 * Loads the script for trustpilot widget
 * - The trustpilot widget only needs to be loaded when using this component
 *
 * Regarding Security - there is no integrity hash for trustPilot... not sure how much I trust them :P
 * https://support.trustpilot.com/hc/en-us/articles/201765546-TrustBox-widgets-FAQ#do-trustbox-widgets-support-subresource-integrity-sri-19
 */
import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-trustpilot',
  imports: [],
  templateUrl: './trustpilot.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustpilotComponent implements AfterViewInit {
  ngAfterViewInit() {
    const script = document.createElement('script');
    script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.defer = true;
    document.head.insertAdjacentElement('beforeend', script);
  }
}
