import { findIndex } from '../../utils/lang';
export function containsAllSetMatcherContext(ruleAttr) {
  return function containsAllMatcher(runtimeAttr) {
    var containsAll = true;
    if (runtimeAttr.length < ruleAttr.length) {
      containsAll = false;
    } else {
      var _loop_1 = function (i) {
        if (findIndex(runtimeAttr, function (e) {
          return e === ruleAttr[i];
        }) < 0) containsAll = false;
      };
      for (var i = 0; i < ruleAttr.length && containsAll; i++) {
        _loop_1(i);
      }
    }
    return containsAll;
  };
}