import { findIndex } from '../../utils/lang';
export function partOfSetMatcherContext(ruleAttr) {
  return function partOfMatcher(runtimeAttr) {
    // To be part of the length should be minor or equal.
    var isPartOf = runtimeAttr.length <= ruleAttr.length;
    var _loop_1 = function (i) {
      // If the length says is possible, we iterate until we prove otherwise or we check all elements.
      if (findIndex(ruleAttr, function (e) {
        return e === runtimeAttr[i];
      }) < 0) isPartOf = false;
    };
    for (var i = 0; i < runtimeAttr.length && isPartOf; i++) {
      _loop_1(i);
    }
    return isPartOf;
  };
}