var KinTabPanel_1;
import { __decorate } from "tslib";
import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import baseStyles from '../styles/base.styles.js';
import componentStyles from './kin-tab-panel.css.js';
/**
 * Tab Panel Component
 *
 * @element kin-tab-panel
 * @slot default - Default slot for panel content.
 */
let KinTabPanel = KinTabPanel_1 = class KinTabPanel extends LitElement {
  constructor() {
    super();
    this.selected = false;
    this.panelId = `${this.tagName.toLowerCase()}-${KinTabPanel_1.instanceCount}`;
    KinTabPanel_1.instanceCount += 1;
  }
  firstUpdated() {
    this.setAttribute('role', 'tabpanel');
    if (!this.hasAttribute('id')) {
      this.id = this.panelId;
    }
  }
  updated(propertyChanges) {
    if (propertyChanges.has('selected')) {
      if (this.selected) {
        this.setAttribute('selected', '');
      } else {
        this.removeAttribute('selected');
      }
    }
  }
  render() {
    return html`<slot class=${classMap({
      'tab-panel': true,
      'tab-panel--selected': this.selected
    })}></slot>`;
  }
  /** Selects the tab panel. */
  select() {
    this.selected = true;
  }
  /** Deselects the tab panel. */
  deselect() {
    this.selected = false;
  }
};
KinTabPanel.styles = [baseStyles, componentStyles];
KinTabPanel.instanceCount = 0;
__decorate([state()], KinTabPanel.prototype, "selected", void 0);
KinTabPanel = KinTabPanel_1 = __decorate([customElement('kin-tab-panel')], KinTabPanel);
export default KinTabPanel;
