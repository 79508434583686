import { splitHttpClientFactory } from './splitHttpClient';
import { objectAssign } from '../utils/lang/objectAssign';
import { SPLITS, IMPRESSIONS, IMPRESSIONS_COUNT, EVENTS, TELEMETRY, TOKEN, SEGMENT, MY_SEGMENT } from '../utils/constants';
import { ERROR_TOO_MANY_SETS } from '../logger/constants';
var noCacheHeaderOptions = {
  headers: {
    'Cache-Control': 'no-cache'
  }
};
function userKeyToQueryParam(userKey) {
  return 'users=' + encodeURIComponent(userKey); // no need to check availability of `encodeURIComponent`, since it is a global highly supported.
}
/**
 * Factory of SplitApi objects, which group the collection of Split HTTP endpoints used by the SDK
 *
 * @param settings validated settings object
 * @param platform object containing environment-specific dependencies
 * @param telemetryTracker telemetry tracker
 */
export function splitApiFactory(settings, platform, telemetryTracker) {
  var urls = settings.urls;
  var filterQueryString = settings.sync.__splitFiltersValidation && settings.sync.__splitFiltersValidation.queryString;
  var SplitSDKImpressionsMode = settings.sync.impressionsMode;
  var flagSpecVersion = settings.sync.flagSpecVersion;
  var splitHttpClient = splitHttpClientFactory(settings, platform);
  return {
    // @TODO throw errors if health check requests fail, to log them in the Synchronizer
    getSdkAPIHealthCheck: function () {
      var url = urls.sdk + "/version";
      return splitHttpClient(url).then(function () {
        return true;
      }).catch(function () {
        return false;
      });
    },
    getEventsAPIHealthCheck: function () {
      var url = urls.events + "/version";
      return splitHttpClient(url).then(function () {
        return true;
      }).catch(function () {
        return false;
      });
    },
    fetchAuth: function (userMatchingKeys) {
      var url = urls.auth + "/v2/auth?s=" + flagSpecVersion;
      if (userMatchingKeys) {
        // `userMatchingKeys` is undefined in server-side
        var queryParams = userMatchingKeys.map(userKeyToQueryParam).join('&');
        if (queryParams) url += '&' + queryParams;
      }
      return splitHttpClient(url, undefined, telemetryTracker.trackHttp(TOKEN));
    },
    fetchSplitChanges: function (since, noCache, till) {
      var url = urls.sdk + "/splitChanges?s=" + flagSpecVersion + "&since=" + since + (filterQueryString || '') + (till ? '&till=' + till : '');
      return splitHttpClient(url, noCache ? noCacheHeaderOptions : undefined, telemetryTracker.trackHttp(SPLITS)).catch(function (err) {
        if (err.statusCode === 414) settings.log.error(ERROR_TOO_MANY_SETS);
        throw err;
      });
    },
    fetchSegmentChanges: function (since, segmentName, noCache, till) {
      var url = urls.sdk + "/segmentChanges/" + segmentName + "?since=" + since + (till ? '&till=' + till : '');
      return splitHttpClient(url, noCache ? noCacheHeaderOptions : undefined, telemetryTracker.trackHttp(SEGMENT));
    },
    fetchMySegments: function (userMatchingKey, noCache) {
      /**
       * URI encoding of user keys in order to:
       *  - avoid 400 responses (due to URI malformed). E.g.: '/api/mySegments/%'
       *  - avoid 404 responses. E.g.: '/api/mySegments/foo/bar'
       *  - match user keys with special characters. E.g.: 'foo%bar', 'foo/bar'
       */
      var url = urls.sdk + "/mySegments/" + encodeURIComponent(userMatchingKey);
      return splitHttpClient(url, noCache ? noCacheHeaderOptions : undefined, telemetryTracker.trackHttp(MY_SEGMENT));
    },
    /**
     * Post events.
     *
     * @param body  Events bulk payload
     * @param headers  Optionals headers to overwrite default ones. For example, it is used in producer mode to overwrite metadata headers.
     */
    postEventsBulk: function (body, headers) {
      var url = urls.events + "/events/bulk";
      return splitHttpClient(url, {
        method: 'POST',
        body: body,
        headers: headers
      }, telemetryTracker.trackHttp(EVENTS));
    },
    /**
     * Post impressions.
     *
     * @param body  Impressions bulk payload
     * @param headers  Optionals headers to overwrite default ones. For example, it is used in producer mode to overwrite metadata headers.
     */
    postTestImpressionsBulk: function (body, headers) {
      var url = urls.events + "/testImpressions/bulk";
      return splitHttpClient(url, {
        // Adding extra headers to send impressions in OPTIMIZED or DEBUG modes.
        method: 'POST',
        body: body,
        headers: objectAssign({
          SplitSDKImpressionsMode: SplitSDKImpressionsMode
        }, headers)
      }, telemetryTracker.trackHttp(IMPRESSIONS));
    },
    /**
     * Post impressions counts.
     *
     * @param body  Impressions counts payload
     * @param headers  Optionals headers to overwrite default ones. For example, it is used in producer mode to overwrite metadata headers.
     */
    postTestImpressionsCount: function (body, headers) {
      var url = urls.events + "/testImpressions/count";
      return splitHttpClient(url, {
        method: 'POST',
        body: body,
        headers: headers
      }, telemetryTracker.trackHttp(IMPRESSIONS_COUNT));
    },
    /**
     * Post unique keys for client side.
     *
     * @param body  unique keys payload
     * @param headers  Optionals headers to overwrite default ones. For example, it is used in producer mode to overwrite metadata headers.
     */
    postUniqueKeysBulkCs: function (body, headers) {
      var url = urls.telemetry + "/v1/keys/cs";
      return splitHttpClient(url, {
        method: 'POST',
        body: body,
        headers: headers
      }, telemetryTracker.trackHttp(TELEMETRY));
    },
    /**
     * Post unique keys for server side.
     *
     * @param body  unique keys payload
     * @param headers  Optionals headers to overwrite default ones. For example, it is used in producer mode to overwrite metadata headers.
     */
    postUniqueKeysBulkSs: function (body, headers) {
      var url = urls.telemetry + "/v1/keys/ss";
      return splitHttpClient(url, {
        method: 'POST',
        body: body,
        headers: headers
      }, telemetryTracker.trackHttp(TELEMETRY));
    },
    postMetricsConfig: function (body, headers) {
      var url = urls.telemetry + "/v1/metrics/config";
      return splitHttpClient(url, {
        method: 'POST',
        body: body,
        headers: headers
      }, telemetryTracker.trackHttp(TELEMETRY), true);
    },
    postMetricsUsage: function (body, headers) {
      var url = urls.telemetry + "/v1/metrics/usage";
      return splitHttpClient(url, {
        method: 'POST',
        body: body,
        headers: headers
      }, telemetryTracker.trackHttp(TELEMETRY), true);
    }
  };
}