import { Component, CUSTOM_ELEMENTS_SCHEMA, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import '@kin/web-components/dist/external/kin-non-prod-banner/kin-non-prod-banner';
import { filter, Subscription } from 'rxjs';
import { BackButtonService } from 'back-button.service';
import { AppConfigModule, AppContext } from './app-config';
import { AppGlobalLoadingContainerComponent } from './app-global-loader/app-global-loader.component';
import { AppLoaderService } from './app-global-loader/service/app-loader.service';
import { FooterComponent } from './common/components/footer/footer.component';
import { HeaderComponent } from './common/components/header/header.component';
import { DebugTrayComponent } from './common/debug/debug-tray.component';
import { GoogleTagManagerService } from './common/services/analytics/google-tagmanager/gtm.service';
import { ContactDialogService } from './create-quote/services/contact-dialog/contact-dialog.service';
import { ContactDialogComponent } from './common/components/contact-dialog/contact-dialog.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent, AppConfigModule, DebugTrayComponent, AppGlobalLoadingContainerComponent, ContactDialogComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppComponent implements OnInit, OnDestroy {
  @AppContext() private _appContext!: AppContext;
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private gtmService = inject(GoogleTagManagerService);

  protected readonly currentYear: number = new Date().getFullYear();
  public hideFooter = true;
  private routerSubscription: Subscription = new Subscription();
  public currentRoute: string = ''; // e.g., "/quote/start"
  public notProd = this._appContext.notProd;
  public showDebugTray = false;
  public loadingService = inject(AppLoaderService);
  private destroyRef = inject(DestroyRef);
  public contactDialogService = inject(ContactDialogService);

  public backButtonService = inject(BackButtonService);
  public isLoading = false;

  ngOnInit() {
    this.backButtonService.initialize();

    // For back button prevention--track forward navigation by pushing new states into the history stack
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.backButtonService.pushNewState(); // Let the service handle state updates
      }
    });

    this.gtmService.init();

    this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.currentRoute = this.router.url;
      this.updateHideFooter();
    });

    this.loadingService.isActive$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isActive) => {
      if (isActive) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    });
  }

  private updateHideFooter() {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    const { data } = route.snapshot;
    this.hideFooter = data['hideFooter'] || false;
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  public toggleDebugTray() {
    this.showDebugTray = !this.showDebugTray;
  }
}
