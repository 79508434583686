import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options/quote-form-options.store';
import { NgKLoggerService } from '@kin/ngk-logger';
import { AppConfigModule, AppContext } from '../../../app-config';
import { mockFormOptions } from './mock-form-options';

@Component({
  selector: 'app-debug-form-options',
  standalone: true,
  imports: [AppConfigModule],
  template: `
    @if (notProd) {
      <div class="card">
        <button (click)="loadOptions()" class="button button--secondary button--sm mt-100">Load Default Form Options</button>
      </div>
    }
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugFormOptionsComponent {
  public logger = inject(NgKLoggerService);
  public quoteFormOptionsStore = inject(QuoteFormOptionsStore);

  @AppContext() private _appContext!: AppContext;
  public notProd = this._appContext.notProd;

  public loadOptions() {
    this.quoteFormOptionsStore.reset(mockFormOptions);
  }
}
