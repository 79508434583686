import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { NgKLoggerService } from '@kin/ngk-logger';
import { WINDOW } from '@ng-web-apis/common';
import { appInitializerFactory } from './app-config';
import { AppConfigService } from './app-config/app-config.service';
import { routes } from './app.routes';
import { LegacyQuotesApiInterceptor } from './common/interceptors/legacy-quotes-api/legacy-quotes-api-interceptor.service';
import { FullstoryService } from './common/services/fullstory/fullstory.service';
import { GuidService } from './common/services/guid/guid.service';
import { SplitIOService } from './common/services/splitIO/splitIO.service';

export const appConfig: ApplicationConfig = {
  providers: [
    AppConfigService,
    GuidService,
    NgKLoggerService,
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding()), // on same route reload setting
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: LegacyQuotesApiInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppConfigService, WINDOW, NgKLoggerService, FullstoryService, GuidService, SplitIOService],
      multi: true,
    },
    provideAnimationsAsync(),
  ],
};
