/**
 * Details for setCookieConfig used by the setCookie() method
 */
export interface SetCookieConfig {
  cookieName: string; // key used to set the cookie
  cookieValue: string; // value of the cookie
  cookieDomain?: string; // domain the cookie should be set on
  daysActive?: number; // how many days the cookie is active, sets the expiration date on the cookie
  path?: string; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#path_attribute
  sameSite?: string; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
  cookieHostname?: string; // defaults to window.location.hostname
}

/**
 * Details for deleteCookieConfig used by the deleteCookie() method
 */
export interface DeleteCookieConfig {
  cookieName: string; // key used to set the cookie
  cookieDomain?: string; // domain the cookie should be set on
  path?: string; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#path_attribute
  sameSite?: string; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
}

export function getCookies() {
  if (document.cookie === '') {
    return [];
  }
  return document.cookie.split('; ').map((cookie) => {
    const [key, value] = cookie.split('=');
    return { key, value };
  });
}

export function getCookie(name: string) {
  return getCookies().find((c) => c.key === name)?.value;
}

/**
 * Set Cookie
 * @param {Object} setCookieConfig - required config for creating a cookie
 * @returns true = success, false = failed
 */
export function setCookie({
  cookieName,
  cookieValue,
  cookieDomain,
  daysActive = 60,
  path = '/', // Path=/: matches any requested URL under the set domain
  sameSite = 'SameSite=Strict; Secure', // "SameSite=Strict": will not provide to third party websites. "Secure": restricts to only https connections
  cookieHostname,
}: SetCookieConfig) {
  const domain: string = cookieDomain ?? getCookieDomain(cookieHostname);
  const date = new Date();
  const futureDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + daysActive);
  document.cookie = `${cookieName}=${cookieValue}; Domain=${domain}; Expires=${futureDate.toUTCString()}; Path=${path}; ${sameSite}`;
  return true;
}

/**
 * Delete Cookie
 * @param {Object} deleteCookieConfig - required config for deleting a cookie
 * @returns true = success, false = failed
 */
export function deleteCookie({
  cookieName,
  cookieDomain,
  path = '/', // default used in setCookie
  sameSite = 'SameSite=Strict; Secure', // default used in setCookie
}: DeleteCookieConfig) {
  if (getCookie(cookieName)) {
    setCookie({
      cookieName,
      cookieValue: '',
      daysActive: -100,
      sameSite,
      path,
      cookieDomain,
    });
  }
}

/**
 * Gets cookie domain
 * - defaults to production (kin.com)
 * - if root domain is used, also applies to subdomains: https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#domain_attribute
 * @returns cookie domain string
 */
export function getCookieDomain(hostname = window.location.hostname): string {
  const allowedDomains = ['localhost', 'kin.com', 'kin.co', 'kin.haus', 'kin.insure'];
  const parsedHostname = new URL(`http://${hostname}`).hostname;

  const matchedDomain = allowedDomains.find((domain) => parsedHostname === domain || parsedHostname.endsWith(`.${domain}`));

  return matchedDomain || 'kin.com';
}
