import { __decorate } from "tslib";
import { LitElement, html } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import baseStyles from '../styles/base.styles.js';
import componentStyles from './kin-tabs.css.js';
import KinTab from './kin-tab.js';
import './kin-tab-panel.js';
/**
 * Tabs Component
 *
 * @element kin-tabs
 * @property [selectedTabIndex=0] - Index of selected tab.
 * @slot default - Default slot for `kin-tab-panel` elements.
 * @slot tablist - named 'tablist' slot for `kin-tab` elements, e.g., `<kin-tab slot="tablist">Tab One</kin-tab>`.
 * @fires {CustomEvent<number>} kin-tabs-select Emitted when tab is selected.
 */
let KinTabs = class KinTabs extends LitElement {
  constructor() {
    super(...arguments);
    this.selectedTabIndex = 0;
    this.firstFocusableTabIndexCounter = 0;
  }
  firstUpdated() {
    this.setValidTabRange();
    this.selectTab();
  }
  willUpdate(changedProperties) {
    if (!changedProperties.has('selectedTabIndex')) {
      return;
    }
    this.setValidTabRange();
  }
  update(changedProperties) {
    super.update(changedProperties);
    if (!changedProperties.has('selectedTabIndex')) {
      return;
    }
    this.selectTab();
  }
  render() {
    return html`
      <div class="tabs">
        <div class="tabs__list" role="tablist" @click=${this.handleTabClick} @keydown=${this.handleTabKeydown}>
          <slot name="tablist"></slot>
        </div>
        <div class="tabs__panels">
          <slot @slotchange=${this.handleSlotChange}></slot>
        </div>
      </div>
    `;
  }
  select(tabIndex) {
    this.selectedTabIndex = tabIndex;
  }
  handleTabClick(e) {
    if (!(e.target instanceof KinTab)) {
      return;
    }
    if (!e.target.disabled) {
      this.selectedTabIndex = this.getTabIndex(e.target);
      this.emitSelect();
    }
  }
  handleTabKeydown(e) {
    var _a;
    if (!(e.target instanceof KinTab)) {
      return;
    }
    /** Treat enter and space as click events. */
    if (['Enter', ' '].includes(e.key)) {
      e.preventDefault();
      this.handleTabClick(e);
      return;
    }
    /** Roving tabindex - @see https://www.w3.org/WAI/ARIA/apg/practices/keyboard-interface/#kbd_roving_tabindex. */
    if (['Home', 'End', 'ArrowRight', 'ArrowLeft'].includes(e.key)) {
      e.preventDefault();
      const tabIndex = this.getTabIndex(e.target);
      const focusIndex = this.firstFocusableTabIndex(tabIndex, e.key);
      (_a = this.tabs[focusIndex]) === null || _a === void 0 ? void 0 : _a.focus();
    }
  }
  /**
   * Find index of first enabled tab.
   *
   * Home
   *   Increment from beginning of array until we find an enabled tab
   * End
   *   Decrement from end of array until we find an enabled tab
   * ArrowRight
   *   Increment from current index until we find an enabled tab
   * ArrowLeft
   *   Decrement from current index until we find an enabled tab
   *
   * If no enabled tabs are found after checking array.length times, give up
   */
  firstFocusableTabIndex(index, key) {
    if (this.firstFocusableTabIndexCounter >= this.tabs.length) {
      this.firstFocusableTabIndexCounter = 0;
      return -1;
    }
    let nextIndex = index;
    if (key === 'Home') {
      nextIndex = 0 + this.firstFocusableTabIndexCounter;
    } else if (key === 'End') {
      nextIndex = this.tabs.length - 1 - this.firstFocusableTabIndexCounter;
    } else if (key === 'ArrowRight') {
      nextIndex = nextIndex >= this.tabs.length - 1 ? 0 : nextIndex + 1;
    } else if (key === 'ArrowLeft') {
      nextIndex = nextIndex <= 0 ? this.tabs.length - 1 : nextIndex - 1;
    }
    if (!this.tabs[nextIndex].disabled) {
      this.firstFocusableTabIndexCounter = 0;
      return nextIndex;
    }
    this.firstFocusableTabIndexCounter += 1;
    return this.firstFocusableTabIndex(nextIndex, key);
  }
  selectTab() {
    var _a;
    if (((_a = this.selectedTab) === null || _a === void 0 ? void 0 : _a.disabled) || this.selectedTab === undefined || this.selectedPanel === undefined) {
      return;
    }
    this.selectedTab.select();
    this.unselectedTabs.forEach(tab => {
      tab.deselect();
    });
    this.selectedPanel.select();
    this.unselectedPanels.forEach(panel => {
      panel.deselect();
    });
  }
  /**
   * Only allow in-range and enabled tabs to be selected.
   */
  setValidTabRange() {
    var _a;
    if (this.tabs.length === 0) {
      return;
    }
    // correct too-high selections
    if (this.selectedTabIndex > this.tabs.length - 1) {
      this.selectedTabIndex = this.tabs.length - 1;
      return;
    }
    // correct too-low selections
    if (this.selectedTabIndex < 0) {
      this.selectedTabIndex = 0;
      return;
    }
    // correct disabled selections
    if ((_a = this.selectedTab) === null || _a === void 0 ? void 0 : _a.disabled) {
      this.selectedTabIndex = this.tabs.findIndex(tab => !tab.disabled);
    }
  }
  handleSlotChange() {
    this.panels.forEach((panel, index) => {
      var _a, _b;
      const tab = this.tabs[index];
      tab === null || tab === void 0 ? void 0 : tab.setAttribute('aria-controls', (_a = panel === null || panel === void 0 ? void 0 : panel.id) !== null && _a !== void 0 ? _a : '');
      panel === null || panel === void 0 ? void 0 : panel.setAttribute('aria-labelledby', (_b = tab === null || tab === void 0 ? void 0 : tab.id) !== null && _b !== void 0 ? _b : '');
    });
  }
  async emitSelect() {
    await this.updateComplete;
    this.dispatchEvent(new CustomEvent('kin-tabs-select', {
      bubbles: true,
      composed: true,
      detail: this.selectedTabIndex
    }));
  }
  getTabIndex(tab) {
    return this.tabs.indexOf(tab);
  }
  get selectedTab() {
    return this.tabs[this.selectedTabIndex];
  }
  get unselectedTabs() {
    return this.tabs.filter(tab => tab !== this.selectedTab);
  }
  get selectedPanel() {
    return this.panels[this.selectedTabIndex];
  }
  get unselectedPanels() {
    return this.panels.filter(panel => panel !== this.selectedPanel);
  }
};
KinTabs.styles = [baseStyles, componentStyles];
__decorate([property({
  type: Number,
  reflect: true
})], KinTabs.prototype, "selectedTabIndex", void 0);
__decorate([queryAssignedElements({
  slot: 'tablist'
})], KinTabs.prototype, "tabs", void 0);
__decorate([queryAssignedElements()], KinTabs.prototype, "panels", void 0);
KinTabs = __decorate([customElement('kin-tabs')], KinTabs);
export default KinTabs;
