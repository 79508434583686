import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as Fullstory from '@fullstory/browser';
import { AppEnvironment } from 'app/app-config';
import { Subscription } from 'rxjs';
import { LegacyApiUsersResponse, LegacyUsersApiService } from '../legacy/users/users.service';

/**
 * https://developer.fullstory.com/browser/get-session-details/#example-invocation
 */
const FS_READY = '_fs_ready';

/**
 * This purpose of this service is to interact with Fullstory (https://fullstory.com), our session tracking/recording integration.
 * Please ask VP of Product or an Engineering Manager for login credentials.
 * These credentials might also be in the company password manager (1Password).
 * Fullstory API Docs: https://developer.fullstory.com/introduction.
 */
@Injectable({
  providedIn: 'root',
})
export class FullstoryService implements OnDestroy {
  public fs = Fullstory;
  public isFullstoryLoaded = false;
  public routerSubscription = new Subscription();
  public userSubscription = new Subscription();

  constructor(
    public router: Router,
    public usersService: LegacyUsersApiService,
  ) {}

  public initialize(env: AppEnvironment, fullstoryOrgID: string, guid: string) {
    if (this.isFullstoryLoaded) {
      return;
    }

    // Only initialize Fullstory on production.
    this.fs.init({
      orgId: fullstoryOrgID,
      devMode: env !== 'production',
    });

    this.isFullstoryLoaded = true;

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (sessionStorage.getItem('user_exists')) {
          this.userSubscription = this.usersService.getUserFromApi().subscribe((resp: LegacyApiUsersResponse) => {
            if (resp.kin_id) {
              this.sendDataToFullStory(resp, guid);
              this.userSubscription.unsubscribe();
              sessionStorage.setItem('fs_user', JSON.stringify(resp));
            }
          });
        }
      }
    });

    // Wait until FullStory is ready, then start sending information about session.
    // @ts-expect-error - Fullstory is not defined in the window object.
    window[FS_READY] = () => {
      sessionStorage.setItem('fs_url', Fullstory.getCurrentSessionURL()!);
    };
  }

  public sendDataToFullStory(data: LegacyApiUsersResponse, guid: string) {
    this.fs.identify(data.kin_id.toString(), {
      displayName: data?.name,
      email: data?.email,
    });

    this.fs.setUserVars({
      uuid: guid,
      address: data?.address,
      state: data?.state,
      product: data?.product,
      property_id: data?.property_id,
      crm_account_id: data?.crm_account_id,
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }
}
