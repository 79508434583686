var KinDrawer_1;
import { __decorate } from "tslib";
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { FocusTrapController } from '@vaadin/a11y-base';
import baseStyles from '../styles/base.styles.js';
import componentStyles from './kin-drawer.css.js';
/**
 * @element kin-drawer
 *
 * @slot default - Default slot, used for main content.
 * @slot header - Named 'header' slot, used for header content.
 * @slot footer - Named 'footer' slot, used for footer content.
 * @prop {Boolean} open - Whether the drawer is open or not.
 * @prop {String} placement - The placement of the drawer. Can be 'start' or 'end'.
 * @prop {Boolean} contained - Whether the drawer is contained or not.
 * @prop {String} width - The width of the drawer. Can be 'narrow', 'wide', or 'full'.
 *
 */
let KinDrawer = KinDrawer_1 = class KinDrawer extends LitElement {
  constructor() {
    super();
    this.open = false;
    this.placement = 'end';
    this.contained = false;
    this.width = 'narrow';
    this.returnFocusEl = null;
    this.focusTrapController = new FocusTrapController(this);
    this.addController(this.focusTrapController);
  }
  render() {
    return html` <div
      class=${classMap({
      drawer: true,
      'drawer--open': this.open,
      'drawer--end': this.placement === 'end',
      'drawer--start': this.placement === 'start',
      'drawer--contained': this.contained,
      'drawer--fixed': !this.contained
    })}
    >
      <div class="drawer__overlay" @click=${this.hide} tabindex="-1"></div>
      <div
        class=${classMap({
      drawer__panel: true,
      'drawer__panel--narrow': this.width === 'narrow',
      'drawer__panel--wide': this.width === 'wide',
      'drawer__panel--full': this.width === 'full'
    })}
        role="dialog"
        aria-modal="true"
        aria-hidden=${this.open ? 'false' : 'true'}
        tabindex="0"
      >
        <header class="drawer__header">
          <span class="drawer__title" id="title">
            <slot name="header"></slot>
          </span>
          <div class="drawer__header-actions">
            <slot name="header-actions"></slot>
            <kin-button class="drawer__close" variant="link" @click=${this.hide}
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" /></svg
            ></kin-button>
          </div>
        </header>

        <slot class="drawer__body"></slot>

        <footer part="footer" class="drawer__footer">
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>`;
  }
  /** Show the drawer */
  show() {
    if (this.open) {
      return;
    }
    this.open = true;
  }
  /** Hide the drawer */
  hide() {
    if (!this.open) {
      return;
    }
    this.open = false;
  }
  updated(changedProperties) {
    if (changedProperties.has('open')) {
      if (this.open === true) {
        /**
         * Store element that was focused at time of drawer open.
         * We will use to restore focus when drawer is closed.
         */
        this.returnFocusEl = document.activeElement;
        /**
         * Activate focus trap.
         *
         * Focus trap will throw an error if it can't find
         * a focusable element.
         */
        try {
          this.focusTrapController.trapFocus(this);
        } catch (e) {
          if (e instanceof Error) console.warn(e.message);
        }
        if (!this.contained) {
          KinDrawer_1.addBodyStyles();
        }
        this.emit('show');
      } else if (this.open === false) {
        /**
         * Deactivate focus trap and
         * return focus to element that was used to trigger open.
         */
        this.focusTrapController.releaseFocus();
        if (!this.contained) {
          KinDrawer_1.removeBodyStyles();
        }
        if (document.contains(this.returnFocusEl) && this.returnFocusEl instanceof HTMLElement) {
          this.returnFocusEl.focus();
        }
        this.returnFocusEl = null;
        this.emit('hide');
      }
    }
  }
  static addBodyStyles() {
    document.body.style.setProperty('overflow', 'hidden');
  }
  static removeBodyStyles() {
    document.body.style.removeProperty('overflow');
  }
  emit(event) {
    this.dispatchEvent(new CustomEvent(`kin-drawer-${event}`, {
      bubbles: true,
      composed: true
    }));
  }
};
KinDrawer.styles = [baseStyles, componentStyles];
__decorate([property({
  type: Boolean,
  reflect: true
})], KinDrawer.prototype, "open", void 0);
__decorate([property({
  reflect: true
})], KinDrawer.prototype, "placement", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
})], KinDrawer.prototype, "contained", void 0);
__decorate([property({
  reflect: true
})], KinDrawer.prototype, "width", void 0);
KinDrawer = KinDrawer_1 = __decorate([customElement('kin-drawer')], KinDrawer);
export default KinDrawer;
