import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-click-leads-loading-data',
  standalone: true,
  template: `@if (this.firstName) {
      <h1 data-test="click-leads-title-with-first-name" class="h3 md:h2 !text-left">{{ this.firstName }}, we're securely transferring you to Kin!</h1>
    } @else {
      <h1 data-test="click-leads-title-without-first-name" class="h3 md:h2 !text-left">We're securely transferring you to Kin!</h1>
    }

    @if (this.address) {
      <p data-test="click-leads-message-with-address" class="block max-w-lg items-center justify-center pt-200 text-left">
        We'll help you find affordable home insurance for <strong>{{ this.address }}</strong
        >. You'll just need to provide a bit more information about you and your home to get your personalized quote.
      </p>
    } @else {
      <p data-test="click-leads-message-without-address" class="block max-w-lg items-center justify-center pt-200 text-left">
        We'll help you find affordable home insurance. You'll just need to provide a bit more information about you and your home to get your personalized quote.
      </p>
    }`,
})
export class ClickLeadsLoadingDataComponent {
  @Input() firstName!: string;
  @Input() address!: string;
}
