import { Injectable, inject, signal } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalStoreActionsService } from 'app/global-store/global-store-actions.service';
import { Router } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { NgKLoggerService } from '@kin/ngk-logger';

/**
 * We need a way to be able to alert and confirm when the user attempts to use their back button
 * The API doesn't support editing responses this way
 * We use the browser's history state and track a index for each route
 * We do this so we can detect if a user is moving forward or backward
 * We also need to support programmatically moving forward to a route that has already been visited/submitted
 */
@Injectable({
  providedIn: 'root',
})
export class BackButtonService {
  private currentIndex = signal(0); // Tracks current navigation index
  private modalActive = signal(false); // Prevents multiple modals from appearing
  private skipModal = false; // Prevents modal logic after user confirms and is navigated away
  private router = inject(Router);

  public location = inject(Location);
  private globalActions = inject(GlobalStoreActionsService);
  private window = inject(WINDOW);
  private logger = inject(NgKLoggerService);

  /**
   * Initializes the service: sets up the initial state and subscribes to back/forward navigation.
   */
  initialize(): void {
    // Push an initial state with index 0
    this.window.history.replaceState({ index: this.currentIndex() }, '', this.window.location.href);

    // Subscribe to location changes (handle back/forward navigation)
    this.location.subscribe(() => this.handleLocationChange());
  }

  /**
   * Handles location changes and determines if a modal needs to be shown for back navigation.
   */
  private handleLocationChange(): void {
    const { state } = this.window.history; // Get the current state from the history stack
    const nextIndex = state?.index ?? 0; // Determine the target index

    if (this.skipModal) {
      // If skipModal is set, reset it and avoid triggering the modal
      this.skipModal = false;
      return;
    }

    if (nextIndex < this.currentIndex() && !this.modalActive()) {
      this.logger.event({
        name: 'back_button_service_back_navigation_detected',
        metaData: {
          currentIndex: this.currentIndex(),
          nextIndex,
        },
      });
      // Back navigation detected

      this.modalActive.set(true);

      this.showConfirmationDialog().then((confirmed) => {
        if (confirmed) {
          // Proceed with back navigation
          this.currentIndex.set(nextIndex);
          this.skipModal = true; // Prevent modal from re-triggering
          this.globalActions.ejectUser('user confirmed back navigation');
          this.router.navigate(['/quote/start']);
          // Wait for the eject user to complete before navigating
          // Navigate to start to prevent seeing error message
          setTimeout(() => {
            this.router.navigate(['/quote/start']);
          }, 0);
        } else {
          // Cancel back navigation
          this.window.history.pushState({ index: this.currentIndex() }, '', this.window.location.href);
        }

        this.modalActive.set(false); // Reset modal state
      });
    } else if (nextIndex > this.currentIndex()) {
      // Forward navigation detected
      this.currentIndex.set(nextIndex); // Update the index for forward navigation
    }
  }

  /**
   * Pushes a new state into the browser's history stack for forward navigation.
   */
  pushNewState(): void {
    const newIndex = this.currentIndex() + 1;
    this.currentIndex.set(newIndex);
    this.window.history.pushState({ index: newIndex }, '', this.window.location.href);
  }

  private showConfirmationDialog(): Promise<boolean> {
    return new Promise((resolve) => {
      const confirmed = this.window.confirm('Going back will cause you to lose your progress, and you may need to start over. Do you want to continue?');
      resolve(confirmed);
    });
  }
}
