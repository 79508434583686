<div data-testid="footer-review-component">
  <div class="container gap-300 p-300">
    <div class="flex flex-col gap-200 sm:flex-row">
      <div role="status" aria-live="polite" class="">
        @if (!isPolicyLoading() && (isLoadingActive$ | async) === false) {
          <div class="flex flex-col">
            <div class="flex items-center">
              <ng-icon name="lucideSparkles" aria-hidden="true" class="my-0.5 !hidden sm:!block" />
              <span id="premium-label" class="my-0.5 font-regular sm:ml-[.5em] sm:font-bold">Annual Premium</span>
              <span aria-labelledby="premium-label" class="my-0.5 ml-auto font-bold sm:ml-[1em] sm:font-regular" data-testid="review-footer-premium">
                {{ policyPremiumSignal() | currency: 'USD' : 'symbol' : '1.0-0' }}
              </span>
            </div>
            <div class="hidden pl-[1.5em] sm:block">
              <span class="my-0.5 text-xs" data-testid="review-footer-address">{{ policyAddressSignal() }}</span>
            </div>
          </div>
        } @else {
          <div class="fs-body-sm flex h-full items-center justify-center gap-200">
            <app-inline-loading-spinner class="text-2xl text-primary" />
            <span class="font-bold">Recalculating your premium...</span>
          </div>
        }
      </div>

      <nav class="ml-auto flex w-full flex-nowrap justify-center space-x-2 sm:w-auto sm:justify-end" aria-label="Quote actions">
        <button data-testid="print-save-button" (click)="handlePrintOrSaveQuote()" type="button" class="button button--md button--block button--secondary my-0.5 w-full sm:w-auto">
          Print/save quote
        </button>
        <a
          [href]="redirectTo()"
          (click)="trackBuyThisPolicyClick()"
          data-testid="submit-button"
          form="quote-form"
          type="submit"
          class="button button--md button--block button--primary my-0.5 w-full sm:w-auto"
          >Buy this policy</a
        >
      </nav>
    </div>
  </div>
</div>
