import { Routes } from '@angular/router';
import { ResumeGuard } from './common/route-guards/resume/resume-guard';

export const routes: Routes = [
  { path: '', redirectTo: '/quote', pathMatch: 'full' },
  {
    path: 'start',
    redirectTo: 'quote/start',
    pathMatch: 'prefix',
  },
  {
    path: 'quote',
    loadChildren: () => import('./create-quote/routing/create-quote.routes').then((m) => m.QuoteRoutes),
  },
  {
    path: 'landing',
    loadComponent: () => import('./create-quote/entry/click-leads/landing.component').then((m) => m.LandingComponent),
    data: { hideFooter: true },
  },
  {
    path: 'begin',
    loadComponent: () => import('./create-quote/entry/resume/resume-my-quote/resume-my-quote.component').then((m) => m.ResumeMyQuoteComponent),
    canActivate: [ResumeGuard],
  },
  {
    path: 'resume',
    loadComponent: () => import('./create-quote/entry/resume/resume/resume.component').then((m) => m.ResumeComponent),
  },
  {
    path: 'resume-email-sent',
    loadComponent: () => import('./create-quote/entry/resume/resume-email-sent/resume-email-sent.component').then((m) => m.ResumeEmailSentComponent),
    data: { hideFooter: true },
  },
  {
    path: 'resume-my-quote/:id',
    loadComponent: () => import('./create-quote/entry/resume/resume-my-quote/resume-my-quote.component').then((m) => m.ResumeMyQuoteComponent),
    data: { hideFooter: true },
  },
  {
    path: 'resume-my-quote',
    loadComponent: () => import('./create-quote/entry/resume/resume-my-quote/resume-my-quote.component').then((m) => m.ResumeMyQuoteComponent),
    data: { hideFooter: true },
  },
  {
    path: 'resume-expired-token',
    loadComponent: () => import('./create-quote/entry/resume/resume-expired-token/resume-expired-token.component').then((m) => m.ResumeExpiredTokenComponent),
    data: { hideFooter: false },
  },
  {
    path: 'thanks',
    loadChildren: () => import('./create-quote/routing/ejection.routes').then((m) => m.EjectionRoutes),
  },
  {
    path: '404',
    loadComponent: () => import('./error-pages/page-not-found/page-not-found.component').then((m) => m.PageNotFoundComponent),
    data: { hideFooter: true },
  },
  {
    path: '**',
    loadComponent: () => import('./error-pages/page-not-found/page-not-found.component').then((m) => m.PageNotFoundComponent),
    data: { hideFooter: true },
  },
];
