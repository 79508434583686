import { __extends } from "tslib";
import { AbstractSplitsCacheSync, usesSegments } from '../AbstractSplitsCacheSync';
import { isFiniteNumber, toNumber, isNaNNumber } from '../../utils/lang';
import { LOG_PREFIX } from './constants';
import { _Set, setToArray } from '../../utils/lang/sets';
import { getStorageHash } from '../KeyBuilder';
/**
 * ISplitsCacheSync implementation that stores split definitions in browser LocalStorage.
 */
var SplitsCacheInLocal = /** @class */function (_super) {
  __extends(SplitsCacheInLocal, _super);
  /**
   * @param {KeyBuilderCS} keys
   * @param {number | undefined} expirationTimestamp
   * @param {ISplitFiltersValidation} splitFiltersValidation
   */
  function SplitsCacheInLocal(settings, keys, expirationTimestamp) {
    var _this = _super.call(this) || this;
    _this.keys = keys;
    _this.log = settings.log;
    _this.storageHash = getStorageHash(settings);
    _this.flagSetsFilter = settings.sync.__splitFiltersValidation.groupedFilters.bySet;
    _this._checkExpiration(expirationTimestamp);
    _this._checkFilterQuery();
    return _this;
  }
  SplitsCacheInLocal.prototype._decrementCount = function (key) {
    var count = toNumber(localStorage.getItem(key)) - 1;
    // @ts-expect-error
    if (count > 0) localStorage.setItem(key, count);else localStorage.removeItem(key);
  };
  SplitsCacheInLocal.prototype._decrementCounts = function (split) {
    try {
      if (split) {
        var ttKey = this.keys.buildTrafficTypeKey(split.trafficTypeName);
        this._decrementCount(ttKey);
        if (usesSegments(split)) {
          var segmentsCountKey = this.keys.buildSplitsWithSegmentCountKey();
          this._decrementCount(segmentsCountKey);
        }
      }
    } catch (e) {
      this.log.error(LOG_PREFIX + e);
    }
  };
  SplitsCacheInLocal.prototype._incrementCounts = function (split) {
    try {
      if (split) {
        var ttKey = this.keys.buildTrafficTypeKey(split.trafficTypeName);
        // @ts-expect-error
        localStorage.setItem(ttKey, toNumber(localStorage.getItem(ttKey)) + 1);
        if (usesSegments(split)) {
          var segmentsCountKey = this.keys.buildSplitsWithSegmentCountKey();
          // @ts-expect-error
          localStorage.setItem(segmentsCountKey, toNumber(localStorage.getItem(segmentsCountKey)) + 1);
        }
      }
    } catch (e) {
      this.log.error(LOG_PREFIX + e);
    }
  };
  /**
   * Removes all splits cache related data from localStorage (splits, counters, changeNumber and lastUpdated).
   * We cannot simply call `localStorage.clear()` since that implies removing user items from the storage.
   */
  SplitsCacheInLocal.prototype.clear = function () {
    this.log.info(LOG_PREFIX + 'Flushing Splits data from localStorage');
    // collect item keys
    var len = localStorage.length;
    var accum = [];
    for (var cur = 0; cur < len; cur++) {
      var key = localStorage.key(cur);
      if (key != null && this.keys.isSplitsCacheKey(key)) accum.push(key);
    }
    // remove items
    accum.forEach(function (key) {
      localStorage.removeItem(key);
    });
    this.hasSync = false;
  };
  SplitsCacheInLocal.prototype.addSplit = function (name, split) {
    try {
      var splitKey = this.keys.buildSplitKey(name);
      var splitFromLocalStorage = localStorage.getItem(splitKey);
      var previousSplit = splitFromLocalStorage ? JSON.parse(splitFromLocalStorage) : null;
      localStorage.setItem(splitKey, JSON.stringify(split));
      this._incrementCounts(split);
      this._decrementCounts(previousSplit);
      if (previousSplit) this.removeFromFlagSets(previousSplit.name, previousSplit.sets);
      this.addToFlagSets(split);
      return true;
    } catch (e) {
      this.log.error(LOG_PREFIX + e);
      return false;
    }
  };
  SplitsCacheInLocal.prototype.removeSplit = function (name) {
    try {
      var split = this.getSplit(name);
      localStorage.removeItem(this.keys.buildSplitKey(name));
      this._decrementCounts(split);
      if (split) this.removeFromFlagSets(split.name, split.sets);
      return true;
    } catch (e) {
      this.log.error(LOG_PREFIX + e);
      return false;
    }
  };
  SplitsCacheInLocal.prototype.getSplit = function (name) {
    var item = localStorage.getItem(this.keys.buildSplitKey(name));
    return item && JSON.parse(item);
  };
  SplitsCacheInLocal.prototype.setChangeNumber = function (changeNumber) {
    // when using a new split query, we must update it at the store
    if (this.updateNewFilter) {
      this.log.info(LOG_PREFIX + 'SDK key, flags filter criteria or flags spec version was modified. Updating cache');
      var storageHashKey = this.keys.buildHashKey();
      try {
        localStorage.setItem(storageHashKey, this.storageHash);
      } catch (e) {
        this.log.error(LOG_PREFIX + e);
      }
      this.updateNewFilter = false;
    }
    try {
      localStorage.setItem(this.keys.buildSplitsTillKey(), changeNumber + '');
      // update "last updated" timestamp with current time
      localStorage.setItem(this.keys.buildLastUpdatedKey(), Date.now() + '');
      this.hasSync = true;
      return true;
    } catch (e) {
      this.log.error(LOG_PREFIX + e);
      return false;
    }
  };
  SplitsCacheInLocal.prototype.getChangeNumber = function () {
    var n = -1;
    var value = localStorage.getItem(this.keys.buildSplitsTillKey());
    if (value !== null) {
      value = parseInt(value, 10);
      return isNaNNumber(value) ? n : value;
    }
    return n;
  };
  SplitsCacheInLocal.prototype.getSplitNames = function () {
    var len = localStorage.length;
    var accum = [];
    var cur = 0;
    while (cur < len) {
      var key = localStorage.key(cur);
      if (key != null && this.keys.isSplitKey(key)) accum.push(this.keys.extractKey(key));
      cur++;
    }
    return accum;
  };
  SplitsCacheInLocal.prototype.trafficTypeExists = function (trafficType) {
    var ttCount = toNumber(localStorage.getItem(this.keys.buildTrafficTypeKey(trafficType)));
    return isFiniteNumber(ttCount) && ttCount > 0;
  };
  SplitsCacheInLocal.prototype.usesSegments = function () {
    // If cache hasn't been synchronized with the cloud, assume we need them.
    if (!this.hasSync) return true;
    var storedCount = localStorage.getItem(this.keys.buildSplitsWithSegmentCountKey());
    var splitsWithSegmentsCount = storedCount === null ? 0 : toNumber(storedCount);
    if (isFiniteNumber(splitsWithSegmentsCount)) {
      return splitsWithSegmentsCount > 0;
    } else {
      return true;
    }
  };
  /**
   * Check if the splits information is already stored in browser LocalStorage.
   * In this function we could add more code to check if the data is valid.
   * @override
   */
  SplitsCacheInLocal.prototype.checkCache = function () {
    return this.getChangeNumber() > -1;
  };
  /**
   * Clean Splits cache if its `lastUpdated` timestamp is older than the given `expirationTimestamp`,
   *
   * @param {number | undefined} expirationTimestamp if the value is not a number, data will not be cleaned
   */
  SplitsCacheInLocal.prototype._checkExpiration = function (expirationTimestamp) {
    var value = localStorage.getItem(this.keys.buildLastUpdatedKey());
    if (value !== null) {
      value = parseInt(value, 10);
      if (!isNaNNumber(value) && expirationTimestamp && value < expirationTimestamp) this.clear();
    }
  };
  // @TODO eventually remove `_checkFilterQuery`. Cache should be cleared at the storage level, reusing same logic than PluggableStorage
  SplitsCacheInLocal.prototype._checkFilterQuery = function () {
    var storageHashKey = this.keys.buildHashKey();
    var storageHash = localStorage.getItem(storageHashKey);
    if (storageHash !== this.storageHash) {
      try {
        // mark cache to update the new query filter on first successful splits fetch
        this.updateNewFilter = true;
        // if there is cache, clear it
        if (this.checkCache()) this.clear();
      } catch (e) {
        this.log.error(LOG_PREFIX + e);
      }
    }
    // if the filter didn't change, nothing is done
  };
  SplitsCacheInLocal.prototype.getNamesByFlagSets = function (flagSets) {
    var _this = this;
    return flagSets.map(function (flagSet) {
      var flagSetKey = _this.keys.buildFlagSetKey(flagSet);
      var flagSetFromLocalStorage = localStorage.getItem(flagSetKey);
      return new _Set(flagSetFromLocalStorage ? JSON.parse(flagSetFromLocalStorage) : []);
    });
  };
  SplitsCacheInLocal.prototype.addToFlagSets = function (featureFlag) {
    var _this = this;
    if (!featureFlag.sets) return;
    featureFlag.sets.forEach(function (featureFlagSet) {
      if (_this.flagSetsFilter.length > 0 && !_this.flagSetsFilter.some(function (filterFlagSet) {
        return filterFlagSet === featureFlagSet;
      })) return;
      var flagSetKey = _this.keys.buildFlagSetKey(featureFlagSet);
      var flagSetFromLocalStorage = localStorage.getItem(flagSetKey);
      var flagSetCache = new _Set(flagSetFromLocalStorage ? JSON.parse(flagSetFromLocalStorage) : []);
      flagSetCache.add(featureFlag.name);
      localStorage.setItem(flagSetKey, JSON.stringify(setToArray(flagSetCache)));
    });
  };
  SplitsCacheInLocal.prototype.removeFromFlagSets = function (featureFlagName, flagSets) {
    var _this = this;
    if (!flagSets) return;
    flagSets.forEach(function (flagSet) {
      _this.removeNames(flagSet, featureFlagName);
    });
  };
  SplitsCacheInLocal.prototype.removeNames = function (flagSetName, featureFlagName) {
    var flagSetKey = this.keys.buildFlagSetKey(flagSetName);
    var flagSetFromLocalStorage = localStorage.getItem(flagSetKey);
    if (!flagSetFromLocalStorage) return;
    var flagSetCache = new _Set(JSON.parse(flagSetFromLocalStorage));
    flagSetCache.delete(featureFlagName);
    if (flagSetCache.size === 0) {
      localStorage.removeItem(flagSetKey);
      return;
    }
    localStorage.setItem(flagSetKey, JSON.stringify(setToArray(flagSetCache)));
  };
  return SplitsCacheInLocal;
}(AbstractSplitsCacheSync);
export { SplitsCacheInLocal };