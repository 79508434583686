import { __decorate } from "tslib";
import { html, LitElement } from 'lit';
import { customElement, property, state, queryAssignedElements } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import baseStyles from '../styles/base.styles.js';
import componentStyles from './kin-accordion.css.js';
/**
 * @element kin-accordion
 * @property [open=false] - Sets whether the accordion is open
 * @property [disabled=false] - Disable opening/closing of accordion
 * @property [variant='default'] - Which variant of the accordion to show, e.g. 'simple'
 * @slot default - Default slot for the accordion details content
 * @slot pre-label - Slot for content that will be positioned inside the summary, before the indicator
 * @slot label - Slot for the accordion summary content
 * @fires {CustomEvent<boolean>} kin-accordion-toggle - Emitted when details is toggled
 * @fires {CustomEvent<null>} kin-accordion-click - Emitted when summary is clicked
 */
let KinAccordion = class KinAccordion extends LitElement {
  constructor() {
    super(...arguments);
    this.open = false;
    this.disabled = false;
    this.variant = 'default';
    this.slotPopulated = false;
    this.icon = html`<svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 13L16 19L22 13"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>`;
  }
  updated(propertyChanges) {
    if (propertyChanges.has('variant')) {
      this.classList.toggle('simple', this.variant === 'simple');
    }
  }
  render() {
    return html`
      <details @toggle=${this.handleToggle} @click=${this.handleClick} ?open=${this.open} ?disabled=${this.disabled}>
        <summary tabindex=${ifDefined(this.disabled ? -1 : undefined)}>
          <slot
            name="pre-label"
            @slotchange=${this.handleSlotchange}
            class=${classMap({
      'pre-label': true,
      'pre-label--visible': this.slotPopulated
    })}
          ></slot>
          ${this.icon} <slot name="label"></slot>
        </summary>
        <slot></slot>
      </details>
    `;
  }
  /** Show the accordion contents. */
  show() {
    this.open = true;
  }
  /** Hide the accordion contents. */
  hide() {
    this.open = false;
  }
  async handleToggle(e) {
    // Keep state in sync with native details element state
    this.open = e.target.open;
    await this.updateComplete;
    this.dispatchEvent(new CustomEvent('kin-accordion-toggle', {
      bubbles: true,
      composed: true,
      detail: this.open
    }));
  }
  async handleClick() {
    if (this.disabled) {
      return;
    }
    await this.updateComplete;
    this.dispatchEvent(new CustomEvent('kin-accordion-click', {
      bubbles: true,
      composed: true
    }));
  }
  handleSlotchange() {
    this.slotPopulated = !!this.slotElements.length;
  }
};
KinAccordion.styles = [baseStyles, componentStyles];
__decorate([property({
  type: Boolean,
  reflect: true
})], KinAccordion.prototype, "open", void 0);
__decorate([property({
  type: Boolean
})], KinAccordion.prototype, "disabled", void 0);
__decorate([property()], KinAccordion.prototype, "variant", void 0);
__decorate([state()], KinAccordion.prototype, "slotPopulated", void 0);
__decorate([queryAssignedElements({
  slot: 'pre-label'
})], KinAccordion.prototype, "slotElements", void 0);
KinAccordion = __decorate([customElement('kin-accordion')], KinAccordion);
export default KinAccordion;
