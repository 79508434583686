@if (notProd) {
  @if (readonly) {
    <div class="card bg-gray-100">
      <pre><code class="text-xs">{{ currentStateSignal() | json }}</code></pre>
    </div>
  } @else {
    <div class="card">
      <label class="select">
        <span class="select__label">Predefined Scenario</span>
        <select class="select__control" [formControl]="preDefinedStateFormControl">
          @for (stateScenario of preDefinedStateList; track i; let i = $index) {
            <option [value]="i">{{ stateScenario.label }}</option>
          }
        </select>
      </label>
      <button (click)="applyStateScenario()" class="button button--secondary button--sm mb-300 mt-100">Apply Scenario To Inject Input</button>

      <label class="textarea">
        <span class="textarea__label">Inject Quote Draft State</span>
        <textarea [formControl]="quoteDraftInjectFormControl" class="textarea__control" placeholder="Quote Draft JSON" rows="15"></textarea>
      </label>

      <div class="form-help">
        Needs to be a valid Quote Draft State (<a class="link" href="https://github.com/kin/quoting-ui/blob/main/src/app/global-store/quote-draft/quote-draft.model.ts" target="_blank" ref="noopener"
          >model</a
        >)
      </div>

      @if (errorMsg) {
        <div class="form-help form-help--invalid">{{ errorMsg }}</div>
      }

      <button (click)="injectQuoteDraft()" [disabled]="!this.quoteDraftInjectFormControl.valid" class="button button--secondary button--sm mt-100">Inject Quote Draft State</button>
    </div>
  }
}
