import { decodeCirJwt } from './decode-cir-jwt';

/**
 * Checks to see if the token exists and is currently valid.
 * @returns true if token found and is valid, false if not.
 */
export function isCirJwtValid(jwtString: string | null): boolean {
  const jwtPayload = decodeCirJwt(jwtString);
  if (!jwtPayload || jwtPayload === null) return false;
  // false if token date = to or before today, true if greater than today.
  const isExpired = jwtPayload.exp <= Date.now() / 1000;
  return !isExpired;
}
