import { JwtCirPayload } from './decode-cir-jwt';

/**
 * Checks to see if the token is expired or not.
 * @returns false if token is not expired, and true if it is expired.
 */
export function isCirJwtExpired(jwtPayload: JwtCirPayload): boolean {
  // false if token date = to or before today, true if greater than today.
  const isExpired = jwtPayload.exp <= Date.now() / 1000;
  return isExpired;
}
