import { Injectable } from '@angular/core';

/**
 * This purpose of this service is to provide integrations (Fullstory, SplitIO, and session-debug header for dot-com)
 * a unique and non-identifying ID to tie sessions together for troubleshooting purposes.
 * This outputs a UUID, i.e. 73906e57-fb83-4209-98c9-655d352480b3.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
 */
@Injectable()
export class GuidService {
  private readonly currentGuid: string;

  constructor() {
    this.currentGuid = crypto.randomUUID();
  }

  get guid() {
    return this.currentGuid;
  }
}
