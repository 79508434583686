import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input, OnInit, signal } from '@angular/core';

import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { watchState } from '@ngrx/signals';
import { JsonPipe } from '@angular/common';
import { AppConfigModule, AppContext } from '../../../app-config';
import { UserStore } from '../../../global-store/user/user.store';
import { generatedMockUserState } from '../../../global-store/user/testing/mock-state-date';
import { UserState } from '../../../global-store/user/user.model';

@Component({
  selector: 'app-debug-user-state',
  standalone: true,
  imports: [AppConfigModule, ReactiveFormsModule, JsonPipe],
  templateUrl: './debug-user-state.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugUserStateComponent implements OnInit {
  private userStore = inject(UserStore);

  @Input() readonly = false;

  @AppContext() private _appContext!: AppContext;
  public notProd = this._appContext.notProd;
  public errorMsg: undefined | string = undefined;
  public currentStateSignal = signal<object>({});

  // controls
  public userDetailsInjectFormControl = new FormControl<string | undefined>(undefined, Validators.required);

  constructor() {
    watchState(this.userStore, (state) => {
      this.currentStateSignal.set(state);
    });
  }

  ngOnInit(): void {
    this.userDetailsInjectFormControl.setValue(JSON.stringify(generatedMockUserState(), null, 2));
  }

  public injectUserDetails() {
    const userDetailsValue = this.userDetailsInjectFormControl.value;
    try {
      if (this.userDetailsInjectFormControl.valid) {
        const newState = JSON.parse(userDetailsValue!) as unknown as Partial<UserState>;
        this.userStore.patchStore(newState);
      }
    } catch (e) {
      this.errorMsg = 'Quote Draft JSON is not valid';
    }
  }
}
