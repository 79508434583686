<div
  class="trustpilot-widget"
  data-locale="en-US"
  data-template-id="53aa8807dec7e10d38f59f32"
  data-businessunit-id="59c518ed0000ff0005abc8fc"
  data-style-height="150px"
  data-style-width="100%"
  data-theme="light"
>
  <a href="https://www.trustpilot.com/review/kin.com" target="_blank" rel="noopener">Trustpilot</a>
</div>
