import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject, OnInit, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppConfigModule, AppConfigService, AppContext } from '../../../app-config';

@Component({
  selector: 'app-debug-api-root',
  standalone: true,
  imports: [AppConfigModule, ReactiveFormsModule, CommonModule],
  template: `
    @if (notProd) {
      <div class="card">
        <pre class="m-b-100"><code class="text-xs">{{ currentContextSignal()| json }}</code></pre>
        <label class="select">
          <span class="select__label">Update QuotingV1 API root:</span>
          <input [formControl]="apiRootControl" class="input__control" type="text" placeholder="API Root URL" />
        </label>
        <div class="form-help">Do NOT include an ending "/" and "/api/v1" is likely required. Ex: "https://rift-pr-1234-app.dev.kin.co/api/v1"</div>
        <button (click)="updateApiRoot()" [disabled]="!this.apiRootControl.valid" class="button button--secondary button--sm mt-100">Update API Root</button>
      </div>
    }
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugApiRootComponent implements OnInit {
  @AppContext() private _appContext!: AppContext;
  public appConfigService = inject(AppConfigService);
  private originalContext = this.appConfigService.appContext;
  public currentContextSignal = signal(this.appConfigService.appContext);

  public notProd = this._appContext.notProd;

  // controls
  public apiRootControl = new FormControl<string>('', Validators.required);

  public ngOnInit(): void {
    const rootUrl = this.originalContext?.apiRoot.quoteV1;
    this.apiRootControl.setValue(rootUrl!);
  }

  public updateApiRoot() {
    if (this.apiRootControl.valid && this.apiRootControl.value !== '') {
      const newContext = { ...this.originalContext, apiRoot: { ...this.originalContext?.apiRoot, quoteV1: this.apiRootControl.value } };
      this.appConfigService.overrideContext(newContext as unknown as AppContext);
      this.currentContextSignal.set(newContext as unknown as AppContext);
    }
  }
}
