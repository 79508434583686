import { __decorate } from "tslib";
import { html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import baseStyles from '../styles/base.styles.js';
import componentStyles from './kin-non-prod-banner.css.js';
export const BANNER_DISMISSED_KEY = 'kin-non-prod-banner-dismissed';
/**
 * @element kin-non-prod-banner
 */
let KinNonProdBanner = class KinNonProdBanner extends LitElement {
  constructor() {
    super(...arguments);
    this.open = window.localStorage.getItem(BANNER_DISMISSED_KEY) === null;
  }
  render() {
    if (!this.open) {
      return '';
    }
    return html`<div class="non-prod-banner">
      <p class="non-prod-banner__content">
        You are in a testing environment. If you see this and you are not a Kin employee, please call
        <a class="non-prod-banner__link" href="tel:8552167674">855-216-7674</a>. Actions taken from this site are not
        legally binding.
      </p>
    </div>`;
  }
  updated() {
    if (!this.open) {
      this.setAttribute('hidden', '');
    } else {
      this.removeAttribute('hidden');
    }
  }
};
KinNonProdBanner.styles = [baseStyles, componentStyles];
__decorate([state()], KinNonProdBanner.prototype, "open", void 0);
KinNonProdBanner = __decorate([customElement('kin-non-prod-banner')], KinNonProdBanner);
export default KinNonProdBanner;
