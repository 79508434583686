@if (notProd) {
  <div class="card">
    <label class="textarea">
      <span class="textarea__label">Enter Resume Token</span>
      <textarea [formControl]="tokenFormControl" class="textarea__control" placeholder="CIR Token" rows="3"></textarea>
    </label>

    <div class="form-help">JWT from the API, we store it in the store under "draftToken"</div>

    @if (errorMsg) {
      <div class="form-help form-help--invalid">{{ errorMsg }}</div>
    }

    @if (tokenDetailsSignal(); as details) {
      <div class="form-help">{{ details }}</div>
    }

    <button (click)="processToken()" [disabled]="!this.tokenFormControl.valid" class="button button--secondary button--sm mt-100">Process Token</button>
  </div>
}
