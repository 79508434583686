import { QuoteDraftState } from '../../../../global-store/quote-draft';
import { mockCoApplicantQuoteDraftState, mockEmptyQuoteDraftState, mockRentalQuoteDraftState } from './quote-draft-states.mock';

/**
 * Use for CoApplicant Tests
 */
export const mockPreDefinedStateList: { json: QuoteDraftState; label: string }[] = [
  {
    label: 'Rental Example',
    json: mockRentalQuoteDraftState,
  },
  {
    label: 'Co-Applicant Example',
    json: mockCoApplicantQuoteDraftState,
  },
  {
    label: 'Empty',
    json: mockEmptyQuoteDraftState,
  },
];
