import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input, signal } from '@angular/core';
import { watchState } from '@ngrx/signals';

import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { AppConfigModule, AppContext } from '../../../app-config';
import { QuoteDraftState, QuoteDraftStore } from '../../../global-store/quote-draft';
import { mockPreDefinedStateList } from './mock-data/quote-draft.mocks';
import { DebugResumeComponent } from '../debug-resume/debug-resume.component';

@Component({
  selector: 'app-debug-quote-draft-state',
  standalone: true,
  imports: [AppConfigModule, ReactiveFormsModule, DebugResumeComponent, JsonPipe],
  templateUrl: './debug-quote-draft-state.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugQuoteDraftStateComponent {
  private quoteDraftStore = inject(QuoteDraftStore);

  @Input() readonly = false;

  @AppContext() private _appContext!: AppContext;
  public notProd = this._appContext.notProd;

  public errorMsg: undefined | string = undefined;
  public quoteDraftInjectFormControl = new FormControl<string | undefined>(undefined, Validators.required);
  public preDefinedStateFormControl = new FormControl<number | undefined>(undefined, Validators.required);
  public preDefinedStateList = mockPreDefinedStateList;
  public currentStateSignal = signal<object>({});

  constructor() {
    watchState(this.quoteDraftStore, (state) => this.currentStateSignal.set(state));
  }

  public injectQuoteDraft() {
    const quoteDraftValue = this.quoteDraftInjectFormControl.value;
    try {
      if (this.quoteDraftInjectFormControl.valid) {
        const newState = JSON.parse(quoteDraftValue!) as unknown as Partial<QuoteDraftState>;
        this.quoteDraftStore.patchStore(newState);
      }
    } catch (e) {
      this.errorMsg = 'Quote Draft JSON is not valid';
    }
  }

  public applyStateScenario() {
    const newStateIndex = this.preDefinedStateFormControl.value;
    if (newStateIndex != null && newStateIndex >= 0 && newStateIndex < this.preDefinedStateList.length) {
      const newState = this.preDefinedStateList[newStateIndex]?.json;
      if (newState) {
        this.quoteDraftInjectFormControl.setValue(JSON.stringify(newState, null, 2));
      }
    } else {
      this.errorMsg = 'Scenario is not defined';
    }
  }
}
