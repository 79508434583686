import { AttributesCacheInMemory } from '../storages/inMemory/AttributesCacheInMemory';
import { validateAttributesDeep } from '../utils/inputValidation/attributes';
import { objectAssign } from '../utils/lang/objectAssign';
/**
 * Add in memory attributes storage methods and combine them with any attribute received from the getTreatment/s call
 */
export function clientAttributesDecoration(log, client) {
  var attributeStorage = new AttributesCacheInMemory();
  // Keep a reference to the original methods
  var clientGetTreatment = client.getTreatment;
  var clientGetTreatmentWithConfig = client.getTreatmentWithConfig;
  var clientGetTreatments = client.getTreatments;
  var clientGetTreatmentsWithConfig = client.getTreatmentsWithConfig;
  var clientGetTreatmentsByFlagSets = client.getTreatmentsByFlagSets;
  var clientGetTreatmentsWithConfigByFlagSets = client.getTreatmentsWithConfigByFlagSets;
  var clientGetTreatmentsByFlagSet = client.getTreatmentsByFlagSet;
  var clientGetTreatmentsWithConfigByFlagSet = client.getTreatmentsWithConfigByFlagSet;
  var clientTrack = client.track;
  function getTreatment(maybeKey, maybeFeatureFlagName, maybeAttributes) {
    return clientGetTreatment(maybeKey, maybeFeatureFlagName, combineAttributes(maybeAttributes));
  }
  function getTreatmentWithConfig(maybeKey, maybeFeatureFlagName, maybeAttributes) {
    return clientGetTreatmentWithConfig(maybeKey, maybeFeatureFlagName, combineAttributes(maybeAttributes));
  }
  function getTreatments(maybeKey, maybeFeatureFlagNames, maybeAttributes) {
    return clientGetTreatments(maybeKey, maybeFeatureFlagNames, combineAttributes(maybeAttributes));
  }
  function getTreatmentsWithConfig(maybeKey, maybeFeatureFlagNames, maybeAttributes) {
    return clientGetTreatmentsWithConfig(maybeKey, maybeFeatureFlagNames, combineAttributes(maybeAttributes));
  }
  function getTreatmentsByFlagSets(maybeKey, maybeFlagSets, maybeAttributes) {
    return clientGetTreatmentsByFlagSets(maybeKey, maybeFlagSets, combineAttributes(maybeAttributes));
  }
  function getTreatmentsWithConfigByFlagSets(maybeKey, maybeFlagSets, maybeAttributes) {
    return clientGetTreatmentsWithConfigByFlagSets(maybeKey, maybeFlagSets, combineAttributes(maybeAttributes));
  }
  function getTreatmentsByFlagSet(maybeKey, maybeFlagSet, maybeAttributes) {
    return clientGetTreatmentsByFlagSet(maybeKey, maybeFlagSet, combineAttributes(maybeAttributes));
  }
  function getTreatmentsWithConfigByFlagSet(maybeKey, maybeFlagSet, maybeAttributes) {
    return clientGetTreatmentsWithConfigByFlagSet(maybeKey, maybeFlagSet, combineAttributes(maybeAttributes));
  }
  function track(maybeKey, maybeTT, maybeEvent, maybeEventValue, maybeProperties) {
    return clientTrack(maybeKey, maybeTT, maybeEvent, maybeEventValue, maybeProperties);
  }
  function combineAttributes(maybeAttributes) {
    var storedAttributes = attributeStorage.getAll();
    if (Object.keys(storedAttributes).length > 0) {
      return objectAssign({}, storedAttributes, maybeAttributes);
    }
    return maybeAttributes;
  }
  return objectAssign(client, {
    getTreatment: getTreatment,
    getTreatmentWithConfig: getTreatmentWithConfig,
    getTreatments: getTreatments,
    getTreatmentsWithConfig: getTreatmentsWithConfig,
    getTreatmentsByFlagSets: getTreatmentsByFlagSets,
    getTreatmentsWithConfigByFlagSets: getTreatmentsWithConfigByFlagSets,
    getTreatmentsByFlagSet: getTreatmentsByFlagSet,
    getTreatmentsWithConfigByFlagSet: getTreatmentsWithConfigByFlagSet,
    track: track,
    /**
     * Add an attribute to client's in memory attributes storage
     *
     * @param {string} attributeName Attrinute name
     * @param {string, number, boolean, list} attributeValue Attribute value
     * @returns {boolean} true if the attribute was stored and false otherways
     */
    setAttribute: function (attributeName, attributeValue) {
      var attribute = {};
      attribute[attributeName] = attributeValue;
      if (!validateAttributesDeep(log, attribute, 'setAttribute')) return false;
      log.debug("stored " + attributeValue + " for attribute " + attributeName);
      return attributeStorage.setAttribute(attributeName, attributeValue);
    },
    /**
     * Returns the attribute with the given name
     *
     * @param {string} attributeName Attribute name
     * @returns {Object} Attribute with the given name
     */
    getAttribute: function (attributeName) {
      log.debug("retrieved attribute " + attributeName);
      return attributeStorage.getAttribute(attributeName + '');
    },
    /**
     * Add to client's in memory attributes storage the attributes in 'attributes'
     *
     * @param {Object} attributes Object with attributes to store
     * @returns true if attributes were stored an false otherways
     */
    setAttributes: function (attributes) {
      if (!validateAttributesDeep(log, attributes, 'setAttributes')) return false;
      return attributeStorage.setAttributes(attributes);
    },
    /**
     * Return all the attributes stored in client's in memory attributes storage
     *
     * @returns {Object} returns all the stored attributes
     */
    getAttributes: function () {
      return attributeStorage.getAll();
    },
    /**
     * Removes from client's in memory attributes storage the attribute with the given name
     *
     * @param {string} attributeName
     * @returns {boolean} true if attribute was removed and false otherways
     */
    removeAttribute: function (attributeName) {
      log.debug("removed attribute " + attributeName);
      return attributeStorage.removeAttribute(attributeName + '');
    },
    /**
     * Remove all the stored attributes in the client's in memory attribute storage
     */
    clearAttributes: function () {
      return attributeStorage.clear();
    }
  });
}