<header class="app-header">
  <div class="app-header__container container">
    <!-- 
      NOTE:
      We want the referrer for analytics so we know people came to kin.com from quoting, which is why `noreferrer` is missing from the `rel` attribute.
    -->
    <a href="https://www.kin.com" rel="noopener" class="outline-none focus:ring-2 focus:ring-primary">
      <svg xmlns="http://www.w3.org/2000/svg" class="app-header__logo c-primary" fill="none" viewBox="0 0 207 114">
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="m36.982 76.499 23.584-24.172c1.584-1.765.633-4.617-2.038-4.617H44.135c-1.72 0-2.852.95-3.938 1.901l-20.732 22.27V3.034C19.464 1.448 18.061 0 16.477 0H2.987C1.404 0 0 1.448 0 3.033V108.23c0 1.585 1.403 3.033 2.988 3.033H16.43c1.585 0 2.988-1.448 2.988-3.033V81.388l23.9 28.608c.453.633 1.72 1.267 2.67 1.267H63.1c2.671 0 3.622-2.716 2.037-4.436L36.982 76.499ZM74.1 25.485c0-6.02 4.753-10.955 10.773-10.955 6.202 0 11.09 4.934 11.09 10.955 0 6.02-4.934 10.954-11.09 10.954-6.02 0-10.773-4.934-10.773-10.954Zm72.335 20.867c-12.177 0-19.917 6.835-22.316 9.37l-2.218-5.885c-.453-1.131-1.268-1.9-2.671-1.9h-6.021a2.994 2.994 0 0 0-2.987 3.032v57.533c0 1.584 1.403 3.033 2.987 3.033h11.724c2.988 0 3.938-.815 3.938-3.033V73.557c1.585-4.3 6.654-10.004 14.893-10.004 7.604 0 11.859 5.25 11.859 13.49v31.459c0 1.584 1.268 3.033 2.988 3.033h12.991c1.585 0 2.988-1.449 2.988-3.033v-30.51c0-16.567-8.103-31.64-28.155-31.64ZM91.663 47.71H78.536a2.994 2.994 0 0 0-2.988 3.033v57.533c0 1.584 1.404 3.032 2.988 3.032h13.127c1.584 0 2.988-1.448 2.988-3.032V50.743a3.023 3.023 0 0 0-2.988-3.033Zm93.474 53.821c0-6.02 4.753-10.954 10.773-10.954 6.201 0 11.09 4.934 11.09 10.954 0 6.02-4.934 10.954-11.09 10.954-6.02 0-10.773-4.934-10.773-10.954Z"
          clip-rule="evenodd"
        />
      </svg>
    </a>
    <button
      data-testid="contact-agent-button"
      (click)="contactDialogService.open()"
      class="phone-link m-l-auto fs-body-md fw-bold px-2 outline-none focus:ring-2 focus:ring-primary"
      aria-label="contact an agent"
    >
      <ng-icon name="lucidePhone" class="phone-link__icon c-primary flex" aria-hidden="true"></ng-icon>
      <span class="phone-link__text"> Need help? Contact an agent </span>
    </button>
  </div>
</header>
